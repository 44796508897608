import LetterModel from '@/views/landing/letter/model/LetterModel';
import { pageValidated } from '@lemontree-ai/lemontree-admin-common-front/utils/validateUtils'
import { groupEncryptWithRSA } from '@lemontree-ai/lemontree-admin-common-front/utils/RsaUtils';
import { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class LetterDetailViewModel {
  constructor() {
    this.model = {};
    this.isModify = false;
    this.registerValid = {
      // date:{
      //   value: 'date',
      //   valueType:'',
      //   isValid:false,
      // },
      title:{
        value: 'title',
        valueType:'',
        isValid:false,
      },
      contents:{
        value: 'contents',
        valueType:'',
        isValid:false,
      },
    }
    this.modifyValid = {
      // date:{
      //   value: 'date',
      //   valueType:'',
      //   isValid:false,
      // },
      title:{
        value: 'title',
        valueType:'',
        isValid:false,
      },
      contents:{
        value: 'contents',
        valueType:'',
        isValid:false,
      },
    }
  }

  init(id){
    this.model = new LetterModel();
    if(id){
      this.isModify = true;
      this.getLetterDetail(id);
    }
  }

  onClickRow(){
    router.push({ name: 'LANDING_LETTER_DETAIL' });
  }

  registerValidatedResult(){
    return pageValidated(this.registerValid, this.model)
  }
  modifyValidatedResult(){
    return pageValidated(this.modifyValid, this.model)
  }

  onClickDelete(){
    const requestDelete = () => {
      this.deleteLetterDetail();
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `발송한 레몬레터를 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  onClickRegisterCompelete(){
    if(this.isModify){
      this.putLetterDetail();
    }else{
      this.postLetterDetail();
    }
  }
  // 상세
  getLetterDetail(id){
    const path = `${apiPath.LETTER}/${id}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.model.setData(resultData);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 등록
  postLetterDetail(){
    const path = `${apiPath.LETTER}`;
    const data = this.model.getRegisterData();
    POST_REQUEST(path, data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '발송한 레몬레터가 등록되었습니다');
      router.push({ name: 'LANDING_LETTER_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 수정
  putLetterDetail(){
    const path = `${apiPath.LETTER}/${this.model.id}`;
    const data = this.model.getModifyData();
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      store.dispatch('commonToast/fetchToastStart', '발송한 레몬레터가 수정되었습니다');
      router.push({ name: 'LANDING_LETTER_DETAIL' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 삭제
  deleteLetterDetail(){
    const path = `${apiPath.LETTER}/${this.model.id}`;
    DELETE_REQUEST(path).then(
    (success) => {
      store.dispatch('commonToast/fetchToastStart', '발송한 레몬레터가 삭제되었습니다');
      router.push({ name: 'LANDING_LETTER_LIST' });
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}