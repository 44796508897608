<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발송한 레몬레터 상세</strong>
        </div>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>등록일시</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.createdAt | convertDateFormat() }}</span>
          </td>
        </tr>
        <tr>
          <th>제목</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.title }}</span>
          </td>
        </tr>
        <tr>
          <th>미리보기</th>
          <td>
            <div class="letter_preview">
              <div class="inner_preview">
                <div class="layer_head">
                  <h3 class="tit_layer">{{ viewModel.model.title }}</h3>
                </div>
                <div class="layer_body">
                  <div v-html="viewModel.model.contents"></div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="LANDING_LETTER_LIST"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="fourthly"
          text="삭제"
          @onClickBtn="viewModel.onClickDelete()"/>
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="수정"
          routeName="LANDING_LETTER_MODIFY"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// viewModel
import LetterDetailViewModel from '@/views/landing/letter/viewModel/LetterDetailViewModel'

export default {
  name:'LetterRegister',
  components:{
    PageWithLayout,
    TableView,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id
    this.viewModel.init(id);
  },
  data(){
    return{
      viewModel: new LetterDetailViewModel(),
    }
  },
}
</script>
<style scoped>
.letter_preview{padding:15px;background-color:rgba(0,0,0,.8)}
.letter_preview .inner_preview{display:inline-flex;flex-direction:column;position:relative;width:100%;max-height:calc(100vh - 100px);min-height:100px;padding:40px 0 60px;border-radius:18px;background-color:#fff;white-space:normal;vertical-align:middle;box-sizing:border-box;text-align:center}

.letter_preview .layer_head{padding:0 66px 20px}
.letter_preview .layer_head .tit_layer{font-weight:700;font-size:24px;line-height:46px;color:#333}

.letter_preview .layer_body{overflow-y:auto;max-height:500px;padding:0 66px;font-weight:600;text-align:left}
</style>