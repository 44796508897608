export default class LetterModel {
  constructor(){
    this.objRaw = {};
    this.id = '';
    // this.date = '';
    this.title = '';
    this.contents = '';
    this.createdAt = '';
    this.updatedAt = '';
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { id, title, contents, createdAt, updatedAt } = obj;
    this.id = id;
    // this.date = date;
    this.title = title;
    this.contents = contents;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
  getRegisterData(){
    let obj = {
      // date : this.date,
      title : this.title,
      contents : this.contents,
    }
    return obj;
  }
  getModifyData(){
    let obj = {
      // date : this.date,
      title : this.title,
      contents : this.contents,
    }
    return obj;
  }
}